/*======================
  GENERAL
  ======================*/

body {
  margin: 0;
  font-family: "Chivo", "Montserrat", "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(to right, #512DA8, #711e72); */
  background: linear-gradient(to right, #0b2d6c, #14b6ff);
}

a,
a:hover,
a:focus,
button {
  text-decoration: none !important;
  outline: none !important;
}

a,
a:active,
a:focus {
  color: #ffffff;
}

a:hover {
  color: #d6cdcd;
}

p {
  font-size: 14.4px;
  line-height: 24px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Chivo", "Montserrat", "Poppins", sans-serif;
}

.h1,
h1 {
  font-size: 36px;
}

.h2,
h2 {
  font-size: 30px;
}

.h3,
h3 {
  font-size: 24px;
}

.h4,
h4 {
  font-size: 18px;
}

.h5,
h5 {
  font-size: 14px;
}

.h6,
h6 {
  font-size: 12px;
}

b {
  font-weight: 500;
}

::-moz-selection {
  background-color: #2e3138;
  color: #fff;
}

::selection {
  background-color: #2e3138;
  color: #fff;
}

.container {
  max-width: 1000px;
}

.alignitems-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*======================
  NAVBAR
  ======================*/

.navbar-custom {
  padding: 20px 0px;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  transition: top 0.3s;
}

.navbar-custom .navbar-brand {
  text-transform: uppercase;
  color: #fff !important;
  font-weight: 700;
  letter-spacing: 2px;
  height: auto;
  font-family: "Chivo", "Montserrat", "Poppins", sans-serif;
}

.navbar-custom .navbar-nav a {
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
  background-color: transparent !important;
  padding: 15px 0;
  margin: 0 10px;
  transition: all 0.4s;
}

.navbar-custom .navbar-nav a:hover,
.navbar-custom .navbar-nav a:focus,
.navbar-custom .navbar-nav a:active {
  color: #fff;
}

.navbar-toggler {
  border: 0;
}

.navbar-toggler:focus,
.navbar-toggler:active:focus,
.navbar-toggler.active:focus,
.navbar-toggler.focus,
.navbar-toggler:active.focus,
.navbar-toggler.active.focus {
  outline: none !important;
  box-shadow: none;
}

/*======================
  PRESALE COUNTDOWN
  ======================*/

.presalecountdown {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.presalecountdown .title {
  color: #ffffff;
}

.countdown-row {
  width: 100%;
  margin-top: 20px;
  column-gap: 15px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.countdown-card {
  display: flex;
  padding: 8px;
  width: 100px;
  text-align: center;
  border-radius: 8px;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.countdown-card .value {
  font-size: 3em;
}

.countdown-card .value {
  color: #ffffff;
}

.countdown-card .type {
  color: #ffffff;
}

/*======================
  PRESALE ACTION
  ======================*/

.presalewallet .btn {
  width: 100%;
  border-radius: 1.25em;
  margin-top: 5rem !important;
  margin-bottom: 3rem !important;
}

.presalewallet .btn:focus,
.presalewallet .btn:active:focus,
.presalewallet .btn.active:focus,
.presalewallet .btn.focus,
.presalewallet .btn:active.focus,
.presalewallet .btn.active.focus {
  outline: none !important;
  box-shadow: none;
}

.walletbtn-content {
  align-items: center;
  padding: 6px;
}

.connect-wallet-text {
  font-weight: 700 !important;
}

.network-text {
  font-size: 0.8em;
}

.extra-msg {
  font-size: 0.6em;
  font-style: italic;
}

.presaledeposit {
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.current-lema-rate {
  text-align: right !important;
  font-style: italic !important;
}

.presaleaction-card {
  border-radius: 15px;
  padding: 20px 5px 15px 5px;
}

.presale-transaction {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.presaleaction .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 2px;
}

.presaleaction .input-group .frm:focus,
.presaleaction .input-group .frm:active:focus,
.presaleaction .input-group .frm.active:focus,
.presaleaction .input-group .frm.focus,
.presaleaction .input-group .frm:active.focus,
.presaleaction .input-group .frm.active.focus,
.presaleaction .input-group .frm:disabled {
  outline: none !important;
  box-shadow: none;
  background: transparent;
}

.busd-input {
  background-color: transparent;
  border: none;
  font-weight: bold;
}

.lema-input {
  background-color: transparent;
  border: none;
  font-weight: bold;
}

.presaleaction .input-group input::placeholder {
  font-weight: bold;
}

.presaleaction .buy-button {
  border-radius: 30px;
  text-align: center;
  font-size: 12px;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.presaleaction .buy-button:focus,
.presaleaction .buy-button:active:focus,
.presaleaction .buy-button.active:focus,
.presaleaction .buy-button.focus,
.presaleaction .buy-button:active.focus,
.presaleaction .buy-button.active.focus {
  outline: none !important;
  box-shadow: none;
}

.presalewallet .btn-outline-light {
  color: #ffffff;
  background-color: #009cef;
}

.presalewallet .btn-outline-light:hover {
  color: #ffffff;
  border-color: #009cef;
  background-color: #009cef;
}

.presalewallet .btn-outline-light:active {
  color: #ffffff;
  background-color: #009cef;
}

.presaleaction .buy-button {
  color: #ffffff;
  background-color: #3bbc01;
}

.presaleaction .buy-button:hover {
  color: #ffffff;
  background-color: #3bbc01;
  border-color: #3bbc01;
}

.presaleaction .buy-button:active {
  color: #ffffff;
  background-color: #3bbc01;
}

.presaleaction-card {
  background-color: rgba(122, 130, 139, 0.1);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

.presaleaction .input-group {
  border-bottom: 2px solid #ffffff;
}

.presaleaction .input-group input {
  color: #ffffff;
}

.presaleaction .input-group input:focus {
  color: #ffffff;
}

.presaleaction .input-group input::placeholder {
  color: #ffffff;
}

.exchange-icon {
  color: #ffffff;
}

.input-group span {
  color: #ffffff;
}

.current-lema-rate {
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .exchange-icon-div {
    margin-top: 2rem !important;
  }
}

/*======================
  BUSD Information
  ======================*/

.busdinformation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 100px;
  row-gap: 60px;
}

.token-count {
  font-size: 2em;
}

.busdcount {
  padding: 10px;
}

.lemacount {
  padding: 10px;
}

.busdinformation-content-2 {
  width: 300px;
  border-radius: 15px;
  padding: 20px 5px 15px 5px;
}

.token-count-personal {
  font-size: 1em;
  word-break: break-word;
}

.busdinformation-content-2 .claim-button {
  border-radius: 30px;
  text-align: center;
  font-size: 12px;
  padding: 10px;
  width: 50%;
  margin: 10px;
}

.busdinformation-content-2 .claim-button:focus,
.busdinformation-content-2 .claim-button:active:focus,
.busdinformation-content-2 .claim-button.active:focus,
.busdinformation-content-2 .claim-button.focus,
.busdinformation-content-2 .claim-button:active.focus,
.busdinformation-content-2 .claim-button.active.focus {
  outline: none !important;
  box-shadow: none;
}

.busdinformation {
  color: #ffffff;
}

.busdinformation-content-2 {
  background-color: rgba(122, 130, 139, 0.1);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

.busdinformation-content-2 .claim-button {
  color: #ffffff;
  background-color: #3bbc01;
}

.busdinformation-content-2 .claim-button:hover {
  color: #ffffff;
  background-color: #3bbc01;
  border-color: #3bbc01;
}

.busdinformation-content-2 .claim-button:active {
  color: #ffffff;
  background-color: #3bbc01;
}

/*======================
  Presale Information
  ======================*/

.presaleinformation {
  border-radius: 10px;
  padding: 10px;
}

.presaleinformation .title {
  font-size: 2em;
  margin-bottom: 20px;
}

.presaleinfo-image {
  width: 40%;
}

@media screen and (max-width: 768px) {
  .presaleinfo-image {
    width: 90%;
  }
}

.presaleinformation {
  background-color: rgba(122, 130, 139, 0.1);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  color: #ffffff;
}

/*======================
  Contract Information
  ======================*/

.contractinformation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 25px;
}

.contract {
  border-radius: 15px;
  padding: 10px;
}

.contract .address {
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.contract .address a,
.contract .address a:active,
.contract .address a:focus {
  color: #009cef;
}

.contract .address a:hover {
  color: #0680c2;
}

.copy_address {
  margin-left: 10px;
  cursor: copy;
}

.contract {
  background-color: rgba(122, 130, 139, 0.1);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

.contract .title {
  color: #ffffff;
}

.copy_address {
  color: #ffffff;
}

.copy_address:hover {
  color: #ffffffc5;
}

/*======================
  Footer
  ======================*/

.footer {
  height: 240px;
}

.footer .row {
  justify-content: center;
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer .footerStartDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer .footerStart p {
  font-size: 16px;
  margin-top: 26px;
}

.footer .socialLinks {
  margin-bottom: 20px;
}

.socialLinks ul li {
  display: inline-block;
}

footer .logo {
  text-align: center;
  margin-bottom: 6px;
}

.copyRightArea {
  text-align: center;
}

.footer span {
  color: #ffffff;
}

/*======================
  Connect Wallet Modal
  ======================*/

.connectModal,
.accountModal {
  overflow: hidden;
  background: rgb(255, 255, 255);
  box-shadow: rgb(14 14 44 / 10%) 0px 20px 36px -8px,
    rgb(0 0 0 / 5%) 0px 1px 1px;
  border: 1px solid rgb(231, 227, 235);
  border-radius: 20px;
  width: 100%;
  max-height: 100vh;
  z-index: 100;
  width: auto;
  min-width: 290px;
  max-width: 100%;
  padding: 0px;
  margin: 0px;
}

.connectModalOverlay,
.accountModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(95, 43, 185, 0.3);
}

.wallet-header {
  -webkit-box-align: center;
  align-items: center;
  background: linear-gradient(
    139.73deg,
    rgb(229, 253, 255) 0%,
    rgb(243, 239, 255) 100%
  );
  border-bottom: 1px solid rgb(231, 227, 235);
  display: flex;
  padding: 20px 24px;
}

.wallet-header .text {
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0%;
  font-weight: bold;
}

.wallet-header .close-button {
  border: 0px;
  background-color: transparent;
}

.wallet-header .close-button:hover {
  opacity: 0.6;
}

.walletCardDiv {
  max-height: 453px;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.walletcard-button {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: auto;
  -webkit-box-pack: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border: 0px;
  border-radius: 16px;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  padding: 16px 24px;
  background-color: transparent;
  color: rgb(31, 199, 212);
  box-shadow: none;
  width: 100%;
}

.walletcard-button:hover {
  opacity: 0.6;
}

.walletcard-name {
  color: rgb(40, 13, 95);
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;
  margin-top: 8px;
}

.accountWalletCardDiv {
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.accountWalletCardDiv .account {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  line-height: 1.5;
  font-size: 18px;
  margin-bottom: 10px;
}

.accountWalletCardDiv .account-detail {
  display: flex;
  margin-bottom: 25px;
  column-gap: 20px;
}

.accountWalletCardDiv .account-detail .viewbsc,
.accountWalletCardDiv .account-detail .copyAddress {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: fit-content;
  color: rgb(31, 199, 212);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  column-gap: 10px;
  cursor: pointer;
}

.accountWalletCardDiv .account-detail .viewbsc:hover,
.accountWalletCardDiv .account-detail .copyAddress:hover {
  opacity: 0.6;
}

.accountWalletCardDiv .logoutwallet {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.accountWalletCardDiv .logoutwallet .logout-button {
  -webkit-box-align: center;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s;
  height: 32px;
  padding: 0px 16px;
  background-color: transparent;
  border: 2px solid rgb(31, 199, 212);
  box-shadow: none;
  color: rgb(31, 199, 212);
}

.accountWalletCardDiv .logoutwallet .logout-button:hover {
  opacity: 0.7;
}
